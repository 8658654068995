<template>
	<div class="filterbar-container">
		<div class="mrow flex wrap align-center">
			<div class="fluid flex wrap mrow">
				<!-- v-if="navbarSettings.showSearchbar" -->
				<div v-if="datepicker" class="mcol-xs-12 mcol-sm-6 datepicker-container">
					<el-date-picker
						:value="filters.daterange"
						type="daterange"
						range-separator="-"
						format="yyyy/MM/dd"
						value-format="yyyy-MM-dd"
						start-placeholder="От"
						end-placeholder="До"
						unlink-panels
						align="right"
						@input="handleDateRange"
					>
					</el-date-picker>
					<!-- :picker-options="pickerOptions" -->
				</div>

				<SearchBar
					class="search-block mcol-xs-12 mcol-sm-6 fluid"
					:options="searchbarOptions"
					:query="filters.q"
					:clearable="true"
					@submit="setFilters"
				/>
			</div>

			<slot></slot>

			<div v-if="categoryFilter" class="mcol-xs-8 mcol-sm-2 filter-item relative">
				<SimpleSpinner :active="itemsLoading" />
				<el-select
					placeholder="Категория"
					clearable
					:value="filters.categoryId"
					@input="catId => setFilters({ categoryId: catId })"
				>
					<el-option
						v-for="item in categoriesList"
						:key="'category-' + item.id"
						:label="item.title_ru"
						:value="item.id"
					/>
				</el-select>
			</div>

			<div v-if="priceFilter" class="filter-item price-input relative">
				<!-- <SimpleSpinner :active="itemsLoading" /> -->
				<el-input-number
					placeholder="Цена от"
					:controls="false"
					:value="filters.minPrice"
					@change="val => setFilters({ minPrice: val })"
				/>
			</div>

			<div v-if="priceFilter" class="filter-item price-input relative">
				<!-- <SimpleSpinner :active="itemsLoading" /> -->
				<el-input-number
					placeholder="Цена до"
					:controls="false"
					:value="filters.maxPrice"
					@change="val => setFilters({ maxPrice: val })"
				/>
			</div>

			<!-- :value="statusFilterProp ? filters[statusFilterProp] : filters.status" -->
			<div v-if="statusFilter" class="mcol-xs-8 mcol-sm-2 filter-item">
				<el-select
					placeholder="Статус"
					clearable
					:value="filters.status"
					@input="statusId => setFilters({ status: statusId })"
				>
					<el-option
						v-for="item in statusFilterList"
						:key="'status-' + item.id"
						:label="item.name || item.title"
						:value="item.id"
					/>
				</el-select>
			</div>

			<div class="mcol-xs-4 mcol-sm-2 filter-item perPage-item">
				<div class="item-container relative">
					<SimpleSpinner :active="itemsLoading" />
					<el-select
						:disabled="itemsLoading"
						:value="filters.max"
						@change="max => setFilters({ max: max })"
					>
						<el-option
							v-for="max in perPageItems"
							:key="'filterByPerPage-' + max.label"
							:label="max.label"
							:value="max.value"
						/>
					</el-select>
				</div>
			</div>

			<!-- <div class="mcol-xs-12 mcol-sm-4 filter-item">
				<el-button type="primary" native-type="button"
					@click="submit" :loading="isLoading"
					>Найти</el-button
				>
			</div> -->
		</div>
	</div>
</template>

<script>
// import { mockCategoriesList } from '@/constants/global';

export default {
	// functional: true,
	props: {
		filters: Object,
		itemsLoading: Boolean,
		datepicker: Boolean,
		categoryFilter: Boolean,
		priceFilter: Boolean,
		submitButton: Boolean,
		statusFilter: Boolean,
		statusFilterProp: {
			type: String,
			default: ''
		},
		statusFilterList: {
			type: Array,
			default: () => [
				{ id: 0, name: 'Активен' },
				{ id: 1, name: 'Не активен' }
			]
		},
		categoriesList: {
			type: Array,
			default: () => []
		},

		searchbarOptions: {
			type: Object,
			default: () => ({
				prepend: true
				// suffix: 'icomoon icon-search'
			})
		},
		perPageItems: {
			type: Array,
			default: () => [
				{ value: 5, label: '5' },
				{ value: 10, label: '10' },
				{ value: 20, label: '20' },
				{ value: -1, label: 'Все' }
			]
		}
	},

	/*computed: {
		mockCategoriesList: () => mockCategoriesList
	},*/

	beforeDestroy() {},

	methods: {
		setFilters(filters) {
			this.$emit('setFilters', filters);
		},
		handleDateRange(range) {
			this.$emit('handleDateRange', range);
		}
		/*submit() {
			this.setFilters({...this.filters,	...this.localFilters});
		}*/
	}
};
</script>
